import React, { useEffect, useMemo } from "react";
import { SpinnerSize } from "@fluentui/react";
import { Loader, Pagination } from 'Shared/Controls';
import { CommonUtils, DateUtils } from 'Shared/Utils';
import { useCopilotAssets } from "./hooks/useCopilotAssets";
import { CopilotAssetCard } from "./components/card/CopilotAssetCard";
import { VideoDialog } from "./components/video-dailog/VideoDialogComponent";
import { CopilotAsset } from 'Models';
import { CheckboxListComponent } from "./components/checkbox-list/CheckboxListComponent";
import { SearchTextBox } from "./components/searchtextbox/SearchTextBox";
import styles from './copilotassets-page.module.scss';
import { AssetTypeOption } from "./components/assettype-option/AssetTypeOption";
import { SortBy } from "./components/sort-by/SortByComponent";

export const CopilotAssetsPage = () => {
    const {
        copilotAssetPageContent,
        pageSize,
        totalAssets,
        iconName,
        getPrimaryButtonText,
        onPrimaryButtonClick,
        defaultImageUrl,
        selectedVideoUrl,
        isVideoDialogOpen,
        handleVideoDialogClose,
        onTitleSearchInputChange,
        titleSearchInput,
        clearTitleSearchInputChange,
        selectedAssetTypes,
        onAssetTypeChange,
        productTypeSearchInput,
        onProductTypeSearchInputChange,
        onProductTypeSearchInputClear,
        selectedFilters,
        setSelectedFilters,
        setDynamicProductTypeOptions,
        onFilterChange,
        clearAllFilters,
        assetTypeOptions,
        setAssetTypeOptions,
        filteredProductTypeOptions,
        currentPage,
        setCurrentPage,
        sortBy,
        handleSortChange
    } = useCopilotAssets();

    const assetsForCurrentPage = useMemo(() => {
        if (!copilotAssetPageContent) return [];

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, totalAssets);

        // Filter assets based on the current page, selected filter, asset type, and title search input
        const filteredAssets = copilotAssetPageContent.copilotAssets
            ?.filter(asset => {
                // Check if asset matches any selected filter
                const matchesFilter = selectedFilters.length === 0 || selectedFilters.includes(asset.ProductName || '');
                // Check if asset matches any selected asset type
                const matchesAssetType = selectedAssetTypes.length === 0 || selectedAssetTypes.includes(asset.AssetType || '');
                // Check if asset title or description matches the title search input
                const matchesTitleOrDescription =
                    asset.Title.toLowerCase().includes(titleSearchInput.toLowerCase()) ||
                    asset.AssetDescription?.toLowerCase().includes(titleSearchInput.toLowerCase());
                return matchesFilter && matchesAssetType && matchesTitleOrDescription;
            }) || [];

        // Sort the filtered assets based on the selected sorting option
        let sortFunction: (a: CopilotAsset, b: CopilotAsset) => number = (a, b) => 0; // Default sort function
        if (sortBy === 'title') {
            sortFunction = (a, b) => a.Title.localeCompare(b.Title);
        } else if (sortBy === 'date') {
            sortFunction = (a, b) => a.CreatedOn && b.CreatedOn ? new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime() : 0;
        } else if (sortBy === 'dateOldToNew') {
            sortFunction = (a, b) => a.CreatedOn && b.CreatedOn ? new Date(a.CreatedOn).getTime() - new Date(b.CreatedOn).getTime() : 0;
        }

        const sortedAssets = [...filteredAssets].sort(sortFunction);
        return sortedAssets.slice(startIndex, endIndex);
    }, [copilotAssetPageContent, currentPage, pageSize, totalAssets, selectedFilters, selectedAssetTypes, titleSearchInput, sortBy]);



    useEffect(() => {
        const uniqueAssetTypes = Array.from(new Set(copilotAssetPageContent?.copilotAssets?.map(asset => asset.AssetType || '')));

        const options: AssetTypeOption[] = uniqueAssetTypes.map(assetType => ({
            key: assetType,
            text: assetType
        }));

        setAssetTypeOptions(options);
    }, []);


    // Update product type options based on selected asset types
    useEffect(() => {
        const productNamesSet = new Set<string>();

        // Iterate through copilot assets and add unique product names
        copilotAssetPageContent?.copilotAssets?.forEach(asset => {
            if ((selectedAssetTypes.length === 0 || selectedAssetTypes.includes(asset.AssetType || '')) || (selectedFilters.length > 0 && selectedFilters.includes(asset.ProductName||''))) {
                    productNamesSet.add(asset.ProductName || '');
                }          
        });

        // Convert the set of unique product names to an array of dropdown options
        const newProductTypeOptions = Array.from(productNamesSet).map(productName => ({
            key: productName,
            text: productName,
        }));

        // Filter out selected options that are no longer available
        const filteredSelectedFilters = selectedFilters.filter(filter =>
            newProductTypeOptions.some(option => option.key === filter)
        );

        // Update the state with product type options and maintain selected options
        setDynamicProductTypeOptions(newProductTypeOptions);
        setSelectedFilters(filteredSelectedFilters);
    }, [selectedAssetTypes]);


    // Calculate the total count of assets after applying filters and title search
    const filteredTotalAssets = copilotAssetPageContent?.copilotAssets
        ?.filter(asset => {
            // Check if asset matches any selected filter
            const matchesFilter = selectedFilters.length === 0 || selectedFilters.includes(asset.ProductName || '');
            // Check if asset matches any selected asset type
            const matchesAssetType = selectedAssetTypes.length === 0 || selectedAssetTypes.includes(asset.AssetType || '');
            // Check if asset title or description matches the title search input
            const matchesTitleOrDescription =
                asset.Title.toLowerCase().includes(titleSearchInput.toLowerCase()) ||
                asset.AssetDescription?.toLowerCase().includes(titleSearchInput.toLowerCase());
            return matchesFilter && matchesAssetType && matchesTitleOrDescription
        }).length || 0;

    if (!copilotAssetPageContent) {
        return (
            <Loader
                Message="Please wait.."
                Size={SpinnerSize.large}
            />
        );
    }

    return (
        <div className={styles.heading}>
            <h1>{copilotAssetPageContent.heading}</h1>
            <p>{CommonUtils.parseStringToHtml(copilotAssetPageContent.headingDescription)}</p>
            <br />
            <div className={styles.copilotAssets}>
                <div className={styles.leftPanel}>
                    <div className={styles.filtersHeader}>
                        <h2>Filters</h2>
                        <a href="#" onClick={clearAllFilters} className={styles.clearAllLink}>Clear All</a>
                    </div>
                    <div className={styles.separator} />
                    <label htmlFor="productName">Asset types</label>
                    {/* Multiselect dropdown for asset types */}
                    <CheckboxListComponent
                        options={assetTypeOptions}
                        selectedValues={selectedAssetTypes}
                        onChange={onAssetTypeChange}
                        isScrollable={assetTypeOptions.length > 4}
                        optionLength={4} />
                    <br />
                    <label htmlFor="productName">Products</label>
                    <br />
                    <div className={styles.inputDropdownContainer}>
                        <SearchTextBox
                            value={productTypeSearchInput}
                            onChange={onProductTypeSearchInputChange}
                            placeholder="Search"
                            onClear={onProductTypeSearchInputClear}
                        />
                    </div>
                    <CheckboxListComponent
                        options={filteredProductTypeOptions}
                        selectedValues={selectedFilters}
                        onChange={onFilterChange}
                        isScrollable={filteredProductTypeOptions.length > 4}
                        optionLength={4}
                    />
                    <br />
                    <div className={styles.separator} />
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.titleSearchWrapper}>
                        <SearchTextBox
                            value={titleSearchInput}
                            onChange={onTitleSearchInputChange}
                            placeholder="Search"
                            onClear={() => clearTitleSearchInputChange()}
                        />
                        <SortBy onSortChange={handleSortChange} />
                    </div>
                    <br />
                    <div className={styles.copilotAssetsCardsContainer}>
                        {totalAssets > 0 ? (
                            assetsForCurrentPage && assetsForCurrentPage.length > 0 ? (
                                assetsForCurrentPage.map((asset: CopilotAsset, index: number) => (
                                    <CopilotAssetCard
                                        key={`copilot-asset-card-${index}`}
                                        id={`copilot-asset-card-${index}`}
                                        title={(asset.Title)}
                                        titleSearchInput= {titleSearchInput}
                                        description={asset.AssetDescription || ''}
                                        isDescriptionRichtext={false}
                                        imageUrl={asset.AssetType !== 'Video' ? (asset.Thumbnail || defaultImageUrl) : ''}
                                        videoUrl={asset.AssetType === 'Video' ? asset.LinkToAsset : ''}
                                        primaryButtonId={`btn-primary-${asset.Id}`}
                                        primaryButtonText={getPrimaryButtonText(asset.AssetType)}
                                        onPrimaryButtonClick={() => onPrimaryButtonClick(asset)}
                                        secondaryButtonId={`btn-secondary-${asset.Title}-${index + 1}`}
                                        secondaryButtonText="Share"
                                        iconProps={iconName}
                                        asset={asset}
                                        productName={asset.ProductName}
                                        assetOwner={asset.AssetOwner || ''}
                                        modified={asset.Modified}
                                        createdOn={`Uploaded on ${DateUtils.formatUtcDateWithInputFormat(asset.CreatedOn, { day: 'numeric', month: 'short', year: 'numeric' })}`}
                                    />
                                ))
                            ) : (
                                <p>No results found. Please use a different search term. </p>
                            )
                        ) : (
                            <p>No Assets found.</p>
                        )}
                    </div>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={filteredTotalAssets}
                        pageSize={pageSize}
                        onPageChange={setCurrentPage}
                    />
                    <VideoDialog isOpen={isVideoDialogOpen} onClose={handleVideoDialogClose} videoUrl={selectedVideoUrl} />
                </div>
            </div>
        </div>
    );
};
