import {
    DefaultButton,
    Label,
    PrimaryButton
} from '@fluentui/react';
import { BaseProps } from 'Shared/Components';
import styles from '../../copilotassets-page.module.scss';
import { CommonUtils } from 'Shared/utils/common-utils';
import { DateUtils } from 'Shared/utils/date-utils';
import { useState } from 'react';
import { VideoDialog } from '../video-dailog/VideoDialogComponent';
import { CopilotAsset } from 'Models';
import Highlighter from "react-highlight-words";

interface ICopilotAssetCardProps extends BaseProps {
    assetOwner:string;
    id: string;
    title: string;
    description?: string;
    isDescriptionRichtext?: boolean;
    imageUrl?: string;
    videoUrl?: string; // New prop for video URL
    primaryButtonId?: string;
    primaryButtonText?: string;
    onPrimaryButtonClick?: () => void;
    disablePrimaryButton?: boolean;
    secondaryButtonId?: string;
    secondaryButtonText?: string;
    onSecondaryButtonClick?: (asset: CopilotAsset) => void;
    disableSecondaryButton?: boolean;
    createdOn?: string;
    iconProps?: any;
    onMouseEnter?: () => void; 
    onMouseLeave?: () => void;
    asset: CopilotAsset;
    productName?: string;
    modified?:string;
    titleSearchInput:string;
}

export const CopilotAssetCard = (props: ICopilotAssetCardProps) => {
    const { iconProps } = props;
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open/close
    const [isTextCopied, setIsTextCopied] = useState(false); // State for each card to track if text is copied

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleVideoPlay = () => {
        // Open the VideoDialog when the user clicks on the video
        setIsModalOpen(true);
    };

    const highlightText = (text: string, search: string): JSX.Element => {
        return (
            <Highlighter
                highlightClassName="YourHighlightClass" 
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={text} 
            />
        );
    }
    
    //  converts the JSON string into a JavaScript object.
    const parseAssetOwners = (jsonString?: string): string[] => {
        try {
            if (jsonString) {
                const parsed = JSON.parse(jsonString);
                if (Array.isArray(parsed)) {
                    return parsed.map((item: { Title: string }) => item.Title);
                }
            }
        } catch (error) {
            console.error('Error parsing AssetOwner JSON:', error);
        }
        return [];
    };
    const assetOwners = parseAssetOwners(props.assetOwner);

    const onSecondaryButtonClick = (asset: CopilotAsset) => {
        if (!asset.LinkToAsset) return; // If no URL is present, return
        navigator.clipboard.writeText(asset.LinkToAsset); // Copy URL to clipboard
        setIsTextCopied(true); // Set state to indicate text is copied

        // Hide the message after 2 seconds
        setTimeout(() => {
            setIsTextCopied(false);
        }, 1000);
    
    };

    return (
        <div
            className={styles.cardsContainer}
            role="figure"
            id={props.id}
            data-testid={props.id}>
            <div
                className={styles.card}>
                
                <VideoDialog isOpen={isModalOpen} onClose={handleCloseModal} videoUrl={props.videoUrl || ''} />
                <div className={styles.cardBody}> 
                    {props.imageUrl &&
                        <div className={styles.cardImage}>
                            <img
                                src={props.imageUrl}
                                alt={props.title}>
                            </img>
                        </div>
                    }
                    {props.videoUrl && (
                        <iframe
                            width="560"
                            height="226"
                            src={props.videoUrl}
                            title="Video"
                            frameBorder="0"
                            scrolling="no"
                            allowFullScreen
                            onClick={handleVideoPlay}
                        ></iframe>
                    )}
                    <div className={styles.cardHeading}>
                        <Label
                            title={props.title}>
                            {highlightText(props.title, props.titleSearchInput)}
                        </Label>
                    </div> 
                    {props.assetOwner && assetOwners.length > 0 && (
                        <p className={styles.ownersContainer}>
                            Asset Owner(s):
                            {assetOwners.slice(0, 2).map((owner: string, index: number) => (
                                <span key={index} className={styles.badge}>
                                  {owner}
                                 </span>
                            ))}
                       </p>
                    )}
                    {!props.isDescriptionRichtext && <p
                        title={props.description}>
                       {highlightText(props.description || '', props.titleSearchInput)}
                    </p>
                    }
                    {props.isDescriptionRichtext && props.description &&
                        <p title={props.description}>
                            {CommonUtils.parseStringToHtml(props.description)}
                        </p>
                    }
                    {props.modified && 
                        <p className={styles.smallText}>
                            Updated: 
                            <span title={props.modified}>
                                {DateUtils.formatDateToDDMonYYYY(props.modified)}
                            </span>
                        </p>
                    } 
            
                 
                </div>
                <div className={styles.buttonContainer}>
                    {props.secondaryButtonId &&
                        <div className={styles.actionButtons} style={{ position: 'relative' }}>
                            <DefaultButton
                                id={props.secondaryButtonId}
                                data-testid={props.secondaryButtonId}
                                text={props.secondaryButtonText}
                                ariaLabel={props.secondaryButtonText}
                                iconProps={iconProps}
                                onClick={() => onSecondaryButtonClick(props.asset)}
                                disabled={props.disableSecondaryButton}
                                {...props.dataAttributes} />
                            {isTextCopied && <p className={styles.textCopied}>Asset link copied</p>}
                        </div>
                    }
                    
                    {props.primaryButtonId &&
                        <div className={styles.actionButtons}>
                            <PrimaryButton
                                id={props.primaryButtonId}
                                data-testid={props.primaryButtonId}
                                text={props.primaryButtonText}
                                ariaLabel={props.primaryButtonText}
                                onClick={props.onPrimaryButtonClick}
                                disabled={props.disablePrimaryButton}
                                {...props.dataAttributes} />
                        </div>
                    }         
                </div>
            </div>
        </div>
    )
};

